<template>
  <section id="dashboard-ecommerce">

    <div class="row mb-5">
      <div class="col-lg-4 col-md-4 col-12">
        <date-picker :disabled-date="disabledDatesFrom"  :lang="lang" v-model="dashboard_filter.start_date" valueType="format" :first-day-of-week="1"></date-picker> to
        <date-picker :disabled-date="disabledDatesFrom"  :lang="lang" v-model="dashboard_filter.end_date" valueType="format" :first-day-of-week="1"></date-picker> &nbsp;&nbsp;
      </div>
      <div class="col-md-8">
        <button type="button" class="btn btn-danger float-left" @click="getHomepageData">Filter</button>
      </div>
    </div>

    <b-row class="match-height">
      <b-col
        md="6"
      >
        <ecommerce-statistics ref="statsRef" :end_date="dashboard_filter.end_date" :start_date="dashboard_filter.start_date" />
      </b-col>
      <b-col
          md="6"
      >
        <customer-performance-accrual ref="customerPerformanceAccrual" :end_date="dashboard_filter.end_date" :start_date="dashboard_filter.start_date" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
          md="4"
      >
        <outlet-performance-accrual ref="outletPerformanceAccrual" :end_date="dashboard_filter.end_date" :start_date="dashboard_filter.start_date" />
      </b-col>
      <b-col
          md="4"
      >
        <outlet-performance-redemption ref="outletPerformanceRedemption" :end_date="dashboard_filter.end_date" :start_date="dashboard_filter.start_date" />
      </b-col>
      <b-col>
        <outlet-category-accrual-performance ref="outletCategoryAccrualPerformance" :end_date="dashboard_filter.end_date" :start_date="dashboard_filter.start_date" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <loyalty-stats />
      </b-col>
      <!-- Revenue Report Card -->
      <b-col lg="6">
        <loyalty-members-summary />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">


      <b-col
              lg="6"
              md="6"
      >
        <merchants-summary />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'
import OutletPerformanceAccrual from './OutletPerformanceAccrual.vue'
import OutletPerformanceRedemption from './OutletPerformanceRedemption.vue'
import LoyaltyStats from './LoyatlyStats.vue';
import LoyaltyMembersSummary from './LoyaltyMembersSummary.vue'
import MerchantsSummary from './MerchantsSummary.vue'
import CustomerPerformanceAccrual from './CustomerPerformanceAccrual.vue'
import OutletCategoryAccrualPerformance from "./OutletCategoryAccrualPerformance.vue";

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import * as moment from "moment/moment";

export default {
  components: {
    BRow,
    BCol,
    DatePicker,
    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    OutletPerformanceAccrual,
    OutletPerformanceRedemption,
    LoyaltyStats,
    LoyaltyMembersSummary,
    MerchantsSummary,
    CustomerPerformanceAccrual,
    OutletCategoryAccrualPerformance
  },
  data() {
    return {
      dashboard_filter:{
        start_date:"2023-07-01",
        end_date:""
      },
      data: {},
    }
  },
  mounted() {
    this.dashboard_filter.end_date = moment().format('YYYY-MM-DD');
    this.getHomepageData();
  },
  methods: {
    disabledDatesFrom(date){
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today ;
    },
    getHomepageData() {
      this.$refs.statsRef.getHomepageData();
      this.$refs.outletPerformanceRedemption.getOutletPerformanceData()
      this.$refs.outletCategoryAccrualPerformance.getOutletCategoryPerformanceStatsData()
      this.$refs.outletPerformanceAccrual.getOutletPerformanceData()
      this.$refs.customerPerformanceAccrual.getCustomerPerformanceData()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
